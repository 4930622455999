import React from 'react';
import Base from "../core/Base"; 


const UserDashboard = () =>{
    return (
        <Base title="User Dashboard">
        <h1>This is profile</h1>
        </Base>
    );
};


export default UserDashboard;