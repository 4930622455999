import React, {useState} from "react";
import { Link } from "react-router-dom";
import { isAuthenticated } from "../auth/helper";
import Base from "../core/Base";
import { createCategory } from "./helper/adminapicall";



const AddCategory = () => {

    const [name, setName] = useState("")
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)


    const {user, token} = isAuthenticated();

    const goBack = () => (
        <div className="mt-5">
            <Link className="btn btn-sm btn-dark mb-3" to="/admin/dashboard">Admin Home</Link>
        </div>
    )

    const handleChange = event => {
        setError("");
        setName(event.target.value);
    };

    const onSubmit = (event) =>{
        event.preventDefault();
        setError("");
        setSuccess(false);
        createCategory(user._id,token, {name})
        .then(data =>{
            if(data.error){
                setError(true)
            }else{
                setError("");
                setSuccess(true);
                setName("");
            }
        });
        
    };

    const successMessage = () => {
        if (success) {
            return <h4 className="text-success">Category created successfully</h4>
        }
    };

    const warningMessage = () => {
        if (error) {
            return <h4 className="text-success">Failed to created Category</h4>
        }
    };


    const myCategoryForm = () => (
        <form>
            <div className="form-group">
                <p className="lead">Enter the category</p>
                <input type="text"
                className="form-control my-3"
                onChange={handleChange}
                value={name}
                autoFocus
                placeholder="For Ex. Summer"
                />
                <button onClick={onSubmit} className="btn btn-outline-info">Create Category</button>
            </div>
        </form>

    );




    return (
        <Base 
        title="Create a category here" 
        description="Add a new category fro new tshirts"
        className="container bg-info p-4"
        >
            <div className="row bg-white rounded py-2">
                <div className="col-md-8 offset-2">
                    {successMessage()}
                    {warningMessage()}
                    {myCategoryForm()}
                    {goBack()}
                </div>
            </div>
        
        </Base>
    )
};

export default AddCategory;