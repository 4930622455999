import React from 'react'
import Menu from './menu';


const Base = ({
    title ="My Title",
    description = "My description",
    className = "text-white p-4",
    children
}) =>(
    <div>
        <Menu/>
        <div className="container-fluid">
            <div className= "jumbotron text-light text-cente">
                <h2 className="display-4 text-center">{title}</h2>
                <p className="lead text-center">{description}</p>
            </div>
            <div className={className}>{children}</div>
        </div>
        <footer className="footer mt-auto py-3">
            <div className="container-fluid bg-success text-light text-center">
                <h4>If you got any questions, feel free to reach out!</h4>
                <button className="btn btn-warning btn-lg my-2">Contact us</button>
            </div>
            <div className="container">
                <span className="text-muted">
                    An Amazing <span className="text-light text-uppercase"> Wipzent</span> coding pratice
                </span>
            </div>
        </footer>
    </div>
);

export default Base;