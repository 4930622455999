import React from 'react';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Home from "./core/Home";
import Signin from './user/Signin';
import Signup from './user/Signup';
import AdminRout from './auth/helper/AdminRoutes';
import PrivateRoute from './auth/helper/PrivateRoutes';
import UserDashboard from './user/UserDashBoard';
import AdminDashboard from './user/AdminDashBoard';
import AddCategory from "./admin/AddCategory";
import ManageCategories from './admin/manageCateories';
import AddProduct from './admin/AddProduct';
import ManageProducts from './admin/ManageProducts';
import UpdateProduct from './admin/UpdateProduct';
import updateCategory from './admin/UpdateCategory';
import Cart from './core/cart';


const Routes = () =>{
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component ={ Home}/>
                <Route path="/signup" exact component ={ Signup}/>
                <Route path="/signin" exact component ={ Signin}/>
                <Route path="/cart" exact component ={ Cart}/>
                <PrivateRoute  path="/user/dashboard" exact component ={ UserDashboard}/>
                <AdminRout  path="/admin/dashboard" exact component ={ AdminDashboard}/>
                <AdminRout  path="/admin/create/category" exact component ={ AddCategory}/>
                <AdminRout  path="/admin/categories" exact component ={ ManageCategories}/>
                <AdminRout  path="/admin/create/product" exact component ={ AddProduct}/>
                <AdminRout  path="/admin/products" exact component ={ ManageProducts}/>
                <AdminRout  path="/admin/product/update/:productId" exact component ={ UpdateProduct}/>
                <AdminRout  path="/admin/category/update/:categoryId" exact component ={ updateCategory}/>
            </Switch>
        </BrowserRouter>
    );
}


export default Routes