import React, {useState, useEffect} from 'react';
import Base from "../core/Base";
import { Link } from "react-router-dom";
import { isAuthenticated } from '../auth/helper';
import { deleteProduct, getProducts } from './helper/adminapicall';



const ManageProducts = () => {

    const [products, setProducts] = useState([]);

    const {user, token} = isAuthenticated();

    const preload = () => {
        getProducts().then(data => {
            if(data.error){
                console.log(data.error);
            } else {
                setProducts(data)
            }
        });
    };

    useEffect( () => {
        preload()
    }, []);

    const deleteThisProduct = productId => {
        deleteProduct(productId, user._id, token).then(data => {
            if(data.error) {
                console.log(data.error);
            } else {
                preload();
                // setProducts(data);
            }
        })
    }

    return(
        <Base title="Welcome admin" description="Manage products here">
      <h2 className="mb-4">All products:</h2>
      <Link className="btn btn-info" to={`/admin/dashboard`}>
        <span className="">Admin Home</span>
      </Link>
      <div className="row">
        <div className="col-12">
          <h2 className="text-center text-white my-3">Total {products.length} products</h2>

         {products.map((product, index) => {
             return(
                 
              <div className="row text-center mb-2 ">
                <div className="col-3 bg-secondary">
                {/* <p className="text-light">testing</p> */}
                </div>
              <div className="col-3 bg-info rounded">
          <h3 className="text-dark" style={{textAlign: "left",textTransform: "uppercase"}}>{product.name}</h3>
        </div>
        <div className="col-3 bg-secondary">
          <Link
            className="btn btn-success"
            to={`/admin/product/update/${product._id}`}
          >
            <span className="">Update</span>
          </Link>
        </div> 
        <div className="col-3 bg-secondary">
        <button onClick={() =>{
            deleteThisProduct(product._id)
        }} className="btn btn-danger">
            Delete
          </button>
        </div>
        </div>
             )
         })}
        </div>
      </div>
    </Base>
    );
};


export default ManageProducts;