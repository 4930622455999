import React,{useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { isAuthenticated } from "../auth/helper";
import Base from "../core/Base";
import { getCategories,deleteCategory } from "./helper/adminapicall";


const ManageCategories = () => {

    const [categories, setCategories] = useState([]);

    const {user, token} = isAuthenticated();

    const preload = () => {
        getCategories().then(data => {
            if(data.error){
                console.log(data.error);
            } else {
                setCategories(data)
            }
        });
    };

    useEffect( () => {
        preload()
    }, []);


    const deleteThisCategory = categoryId => {
        deleteCategory(categoryId, user._id, token).then(data => {
            if(data.error) {
                console.log(data.error);
            } else {
                preload();
            }
        })
    }
    return(
        <Base>
        <h2 className="mb-4">All Categories:</h2>
      <Link className="btn btn-info" to={`/admin/dashboard`}>
        <span className="">Admin Home</span>
      </Link>
      <div className="row text-center">
        <div className="col-12">
          <h2 className="text-center text-white my-3">Total {categories.length} Categories</h2>

         {categories.map((catName, index) => {
             return(
          <div>
              
          <div className="row">
              <div className="col-4 bg-secondary mb-2">
              <h3 className="text-white text-left badge bg-info" key={index}>{catName.name}</h3>
              </div>
              <div className="col-4 text-center bg-secondary mb-2">
              <Link to={`/admin/category/update/${catName._id}`} className="btn btn-success">Update</Link>
              </div>
              <div className="col-4 bg-secondary mb-2">
              <button onClick={() =>{
                  deleteThisCategory(catName._id)
                  }} className="btn btn-danger">
                    Delete
                </button>
              </div>
          </div>
          </div>
             )
         })}
        </div>
      </div>
        </Base>
    );
};

export default ManageCategories;