import React, {useState, useEffect} from "react";
import "../styles.css";
import {API} from "../backend";
import Base from "./Base";
import Card from "./Card";
import { loadCart } from "./helper/cartHelper";
import StripCheckout from "./helper/stripCheckout";



const Cart =() =>{

    const [products, setProducts] = useState([])
    const [reload, setReload] = useState(false)

    useEffect(() =>{
        setProducts(loadCart());
    },[reload]);

    const loadAllProducts = () =>{
        return(
            <div>
                <h2>This section is to load product</h2>
                {products.map((product, index) => (
                   <div>
                      <Card
                    key={index}
                    product={product}
                    removeFromCart={true}
                    addtoCart={false}
                    setReload={setReload}
                    reload={reload}
                    />
                    </div>
                ))}
            </div>
        )
    }

    // const loadCheckout = () =>{
    //     return(
    //         <div>
    //             <h2>This section is for checkout</h2>
    //         </div>
    //     )
    // }

    return(
        <Base title="Cart page" description="Ready to checkout">
            <div className="row text-center">
                <div className="col-6">{loadAllProducts()}</div>
                <div className="col-6"><StripCheckout
                products={products}
                setReload={setReload}
                /></div>
            </div>
        </Base>
    );
}

export default Cart;