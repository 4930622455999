import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../../auth/helper';
import { cartEmpty, loadCart } from './cartHelper';



const StripCheckout = ({products, setReload = f => f, reload = undefined}) => {

    const [data, setData] = useState({
        loading: false,
        success: false,
        error: "",
        address: ""
    });

    const token = isAuthenticated() && isAuthenticated().token
    const userId = isAuthenticated() && isAuthenticated().user._id

    // const getFinalPrice=() => {
    //     return products.reduce((currentValue, nextValue) => {
    //         return currentValue +nextValue.count * nextValue
    //     }, 0);
    // };

    const getFinalAmount = () => {
        let amount = 0;

        products.map(p => {
            amount = amount + p.price
        });
        return amount;
    };

    const showStripeButton = () => {
        return isAuthenticated() ? (
            <button className="btn btn-success">Pay with Strip</button>
        ) : (
            <Link to="/signin">
                <button className="btn btn-warning">Signing</button>
            </Link>
        );
    };




    return (
        <div>
            <h3 className="text-light">Strip checkout {getFinalAmount()}</h3>
            {showStripeButton()}
        </div>
    );
};

export default StripCheckout;